"use client";

import Image from "next/image";

import { cn } from "../lib/utils";
import Loader from "./loader";

// import { useAppStore } from "../helpers/app-store-provider";

export default function Preloader({ className }: { className?: string }) {
  // TODO : check with @ali
  const { logoUrl } = { logoUrl: "" };
  // useAppStore(state => state)
  return (
    <div
      className={cn(
        "absolute top-0 z-20 flex h-screen w-screen flex-col items-center justify-center",
        className,
      )}
    >
      {logoUrl && (
        <Image
          src={logoUrl}
          alt="logo"
          className="mb-4 h-20 w-20"
          width={80}
          height={80}
        />
      )}
      <Loader />
    </div>
  );
}
